<template>
	<div class="wrapper">
		<div class="content" v-if="!isPc">
			<router-view></router-view>
		</div>
		<v-footer v-if="!isPc"></v-footer>
		<div v-if="isPc">

			<img src="/static/nf.png" style="width: 100%;" />
			<div style="text-align: center;">
				<a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备19009738号-1</a>
			</div>

		</div>
	</div>
</template>

<script>
import vFooter from '../components/footer.vue'
export default {
	data() {
		return {
			isPc: false
		}
	},
	created() {
		if (window.mobile) {

			this.isPc = false

		} else {
			this.isPc = true
			$("#app").css("max-width", "100%")
			document.title = "上海越彬网络科技发展有限公司"
		}
	},
	mounted() {

	},
	methods: {
	},
	components: {
		vFooter
	}
}
</script>

<style scoped="scoped">
.content {

	padding-bottom: 60px;
}
</style>