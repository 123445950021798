<template>
	<div class="footer" >
		<router-link class="link" to="/home">
			<span class="icon icon-index flexcenter"></span>
			<div>首页</div>
		</router-link>
		<router-link class="link" to="/order">
			<span class="icon icon-order flexcenter"></span>
			<div>订单</div>
		</router-link>
		<router-link class="link" to="/cart">
			<span class="icon icon-cart flexcenter"></span>
			<div>购物车</div>
		</router-link>
		<router-link class="link" to="my">
			<span class="icon icon-user flexcenter"></span>
			<div>我的</div>
		</router-link>
	</div>
</template>

<script>
	export default{
		data(){
			return{}
		},
		mounted(){
			this.GetMsg();
		},
		methods:{
			GetMsg(){
				this.$store.dispatch("POST", {
					url: "/api/chat/?action=getnoread"
				}).then(result => {
					
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.footer {
		width: 100%;
		height: 54px;
		position: fixed;
		bottom: 0px;
		z-index: 2;
		background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #9dc3fb), color-stop(100%, #84b2f7));
	}
	
	.link {
		float: left;
		width: 25%;
		text-decoration: none;
		list-style: none;
		text-align: center;
		color: #fff;
		font-size: 12px;
		padding-top: 8px;
	}
	
	.router-link-active {
		/*color: red;*/
	}
	
	.icon {
		display: inline-block;
		width: 28px;
		height: 24px;
	}
	
	.icon-index {
		background: url(../../public/static/images/index.png);
		height: 24px;
		width: 24px;
		background-size: 100% 100%;
	}
	
	.router-link-active .icon-index {
		background: url(../../public/static/images/index1.png);
		height: 24px;
		width: 24px;
		background-size: 100% 100%;
	}
	
	.icon-order {
		background: url(../../public/static/images/order.png);
		height: 24px;
		width: 24px;
		background-size: 100% 100%;
	}
	
	.router-link-active .icon-order{
		background: url(../../public/static/images/order1.png);
		height: 24px;
		width: 24px;
		background-size: 100% 100%;
	}
	
	.icon-cart {
		background: url(../../public/static/images/cart.png);
		height: 24px;
		width: 28px;
		background-size: 100% 100%;
	}
	.router-link-active .icon-cart{
		background: url(../../public/static/images/cart1.png);
		height: 24px;
		width: 28px;
		background-size: 100% 100%;
	}
	
	.icon-user {
		background: url(../../public/static/images/user.png);
		height: 24px;
		width: 22px;
		background-size: 100% 100%;
	}
	.router-link-active .icon-user{
		background: url(../../public/static/images/user1.png);
		height: 24px;
		width: 22px;
		background-size: 100% 100%;
	}
</style>